<template>
  <DialogFormWrapper>
    <template #form>
      <div class="mt-6 mb-8 black--text">
        Czy na pewno chcesz odpiąć kartę?
      </div>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        v-shortkey="{enter:['enter']}"
        @shortkey.native="unassignOrder()"
        @click="unassignOrder()"
      >
        Odepnij kartę
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'

export default {
  components: {
    DialogFormWrapper
  },
  mixins: [afterFormSubmitted],
  computed: {
    ...mapState({
      isProcessing: state => state.bdoCard.isProcessing,
    }),
  },
  methods: {
    ...mapActions({
      closeDialog: 'layout/closeDialog',
      unassignOrderFromBdoCard: 'bdoCard/unassignOrder',
    }),
    unassignOrder () {
      this.unassignOrderFromBdoCard()
        .then(() => this.closeDialog())
    }
  }
}
</script>
